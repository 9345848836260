@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "lacoste";
  src: url("./assets/fonts/Lacoste_SANS/LACOSTE_SANS_EXPANDED_REGULAR.OTF");
}
@font-face {
  font-family: "lacoste-expanded-bold";
  src: url("./assets/fonts/Lacoste_SANS/LACOSTE_SANS_EXPANDED_BOLD.OTF");
}

:root {
  --primary: #00482f; /* Define primary color */
}

html,
body {
  margin: 0;
  height: 100%;
}

input[type="range"].custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* également nécessaire sur le curseur */
  width: 1em;
  height: inherit; /* s'adapte à la hauteur de l'input */
  border: none;
  border-radius: 0; /* pris en compte sur Webkit et Edge */
  background: currentColor; /* pris en compte sur Webkit only */
}

@layer base {
  .btn-notifications-toggle_active {
    @apply text-white bg-primary p-2 font-lacosteExpandedBold transition uppercase;
  }
  .btn-notifications-toggle {
    @apply text-primary p-2 font-lacosteExpandedBold hover:bg-primary-light transition uppercase;
  }
}

.ql-align-center {
  text-align: center;
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@media print  {
  *{
    scrollbar-width: none;
  }

  #media-print{
    width: 100%;
    height: 100%;
  }

  .print-width{
    width: 100%;
  }

  .print-layout{
    padding: 0;
  }

  .modal-header {
    display: none;
  }

  .modal-print-content img {
    display: block;
    max-height: 50%;
    height: auto;
  }

  .text-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
  }

  .element-a {
    display: none;
  }

  
  .border-l{
    border: none;
  }
  @page {
    size: portrait;
  }
  
}
