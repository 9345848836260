.header-container-refs,
.header-container {
  display: flex;
  flex-direction: column;
}

.information-refs-title,
.references-refs-title {
  font-size: 20px;
  text-transform: uppercase;
  font-family: lacoste-expanded-bold, bold;
}

.item-title-refs {
  font-size: 16px;
  font-family: lacoste-expanded-bold, bold;
}

.text-side-refs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.img-container-refs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-img-refs {
  width: 50%;
  max-height: 400px;
  object-fit: contain;
}

.refs-container {
  display: flex;
  gap: 20px;
  padding: 20px 30px;
  width: 100%;
}

.infos-refs-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.subref-container {
  display: flex;
  border-left: 1px solid grey;
  padding-left: 30px;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.header-info,
.header-info-refs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 20px;
  padding: 10px 30px;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-img {
  width: 50%;
  max-height: 40%;
}

.item-img {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.item-sku {
  color: grey;
}

.item-title {
  font-size: 20px;
  font-family: lacoste-expanded-bold, bold;
}

.data-item{
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px 30px;
}